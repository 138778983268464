import {Slider} from 'antd';
import React, {useState} from "react";
import Background from '../../images/Group344.png';

export const SliderFlow = ({ChangeCard, count, width}) => {
    const [value, setValue] = useState(0);

    const handleChange = (e) => {
        setValue(e);
        ChangeCard(e/70);
    }
    return (
        <>
            <Slider tooltipVisible={false} value={value} onChange={(e) => handleChange(e)} handleStyle={{
                backgroundImage: `url(${Background})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '99px',
                height: '40px',
                backgroundColor: '#4285F4',
                borderRadius: '8px',

            }}
                    trackStyle={{
                        // marginTop:'14px',
                        height: '2px',
                    }}
                    railStyle={{
                        backgroundColor: '#333131',
                        height: '2px',
                    }}
                    min={0}
                    max={(count - 1)*70}

                    style={{
                        width: width || '840px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',

                    }}
            />

        </>
    );
}

import React, {useState} from 'react';
import Card from "../../ui/card";
import {Btn, TextButton} from "../constants";
import Arrow from "../../assets/arrowBack.svg";
import {SliderFlow} from "./SliderFlow";
import styled from "styled-components";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {Carousel} from "antd";
import BlackCard from "../../ui/blackCard";
import '../layout.css'
import PlusComponent from "../../ui/plusComponent";

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    draggable: true,
}


const CarouselContainer = styled.div`
  margin-top: 140px;
  margin-left: ${props => props.cards ? '0px' : '8px'};
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const A = styled.a`
  transition-duration: 0.2s;
  color: inherit;
  &:hover {
    color: white;
  }
`;


const SliderCard = ({data, black, title,toggleOpenModal}) => {
        const [value, setValue] = useState(0);
        const screens = useBreakpoint();
        const handleChange = (e) => {
            setValue(e);
        };

        return (
          <>
            <div>
              <PlusComponent text={title} />
              {black ? (
                <CarouselContainer>
                  {screens.md && (
                    <div
                      style={{
                        display: "flex",
                        marginLeft: -350 * value,
                        transition: "1s",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {data.map((item, index) => (
                          <div key={index}>
                            <BlackCard item={item} />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </CarouselContainer>
              ) : (
                <CarouselContainer cards>
                  {screens.md && (
                    <div
                      style={{
                        display: "flex",
                        marginLeft: -220 * value,
                        transition: "1s",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {data.map((item, index) => (
                          <div key={index}>
                            <Card
                              item={item}
                              toggleOpenModal={toggleOpenModal}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </CarouselContainer>
              )}
              {!screens.md && (
                <div style={{ marginTop: -90 }}>
                  <Carousel {...settings} slidesToShow={black ? 1 : 1.3}>
                    {data.map((item, index) => (
                      <div key={index}>
                        <div key={index}>
                          {black ? (
                            <div style={{ width: 200 }}>
                              <BlackCard item={item} />
                            </div>
                          ) : (
                            <Card item={item} />
                          )}
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              )}

              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
              >
                <BtnContainer card={black}>
                  <Btn
                    shape="circle"
                    type="primary"
                    size={"large"}
                    onClick={toggleOpenModal}
                  >
                    <Arrow />
                  </Btn>
                  <TextButton>
                    <A onClick={toggleOpenModal}>ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ</A>
                  </TextButton>
                </BtnContainer>

                {screens.md && (
                  <div style={{ margin: "50px 0 0 145px" }}>
                    <SliderFlow
                      width={
                        screens.xxl
                          ? "840px"
                          : screens.xl
                          ? "600px"
                          : screens.lg
                          ? "450px"
                          : "200px"
                      }
                      count={data.length}
                      ChangeCard={handleChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        );
    }
;

export default SliderCard;


export const BtnContainer = styled.div`
  margin-left: ${props => props.card ? '8px': '0'};
  margin-top: 35px;
  display: flex;
  align-items: center;
  transition-duration: .2s;
  &:hover{
    color:white;
  };
  color: #4285F4;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;



